<template>
  <div class="zone-list">
    <v-card
      max-width="600"
      class="mx-auto mt-2 mb-2 d-flex align-stretch flex-column"
    >
      <v-row no-gutters align="center" justify="center" class="py-1">
        <h3 v-if="worldZone == ''" style="word-break: normal">
          Les zones du monde
        </h3>
        <h3 v-else style="word-break: normal">
          {{ worldZone | capitalize }} : Les zones
        </h3>
      </v-row>
    </v-card>
    <!-- TODO : mettre un if au cas il y a un worldzone present : sous-zone
    sinon c'est une zone principale worldZoneTheme
     -->
    <div v-if="dataReady">
      <CategoryList
        nextCollection="worldZone"
        paramz="setWorldZone"
        listz="listZone"
      />
    </div>
  </div>
</template>

<script>
import CategoryList from "@/component/list/ui/Category.vue";
import Vuex from "vuex";

export default {
  components: {
    CategoryList,
  },
  data() {
    return {
      dataReady: false,
    };
  },
  name: "zone-list",
  mounted: async function () {
    await this.$store.dispatch("vote/getList", {
      loading: null,
      typeList: "zone",
      pathList: "zone",
      // parentList: []
    });
    this.dataReady = true;
  },
  computed: {
    ...Vuex.mapState("list", ["worldZone"]),
  },
};
</script>
