<template>
  <div class="country-list">
    <v-card
      max-width="600"
      class="mx-auto mt-2 mb-2 d-flex align-stretch flex-column"
    >
      <v-row no-gutters align="center" justify="center" class="py-1">
        <h3 style="word-break: normal">Liste des pays</h3>
      </v-row>
    </v-card>

    <div v-if="dataReady">
      <CategoryList
        nextCollection="worldCountry"
        paramz="setWorldCountry"
        listz="listCountry"
      />
      <v-card
        max-width="600"
        class="mx-auto mt-2 mb-1 d-flex align-stretch flex-column"
      >
        <v-row no-gutters align="center" justify="center" class="py-1">
          <h3 style="word-break: normal" class="grey--text text--darken-1">Les autres pays suivront ici...</h3>
        </v-row>
      </v-card>
    </div>
  </div>
</template>

<script>
import CategoryList from "@/component/list/ui/Category.vue";

export default {
  components: {
    CategoryList,
  },
  data() {
    return {
      dataReady: false,
    };
  },
  name: "country-list",
  mounted: async function () {
    await this.$store.dispatch("vote/getList", {
      loading: null,
      typeList: "country",
      pathList: "country",
    });
    this.dataReady = true;
  },
};
</script>
