<template>
  <div>
    <v-card
      class="mx-auto mb-2 d-flex align-stretch flex-column"
      max-width="600"
    >
      <v-row no-gutters align="center" justify="center" class="pt-1">
        <h3 style="word-break: normal">{{ worldName | capitalize }}</h3>
      </v-row>
      <v-row class="justify-center align-center mt-1 mb-1 pb-2 no-gutters">
        <v-btn-toggle :value="worldChoice" borderless>
          <v-btn
            x-small
            class="white--text"
            text
            value="country"
            :disabled="worldChoice == 'country'"
            @click="changeWorldChoice('country')"
          >
            <span>Pays</span>
            <svg-icon type="mdi" :path="flagPath" size="16" right></svg-icon>
          </v-btn>
          <v-btn
            x-small
            class="white--text"
            text
            value="zone"
            :disabled="worldChoice == 'zone'"
            @click="changeWorldChoice('zone')"
          >
            <span>Zones</span>
            <svg-icon type="mdi" :path="mapPath" size="16" right></svg-icon>
          </v-btn>
          <v-btn
            x-small
            class="white--text"
            text
            value="theme"
            :disabled="worldChoice == 'theme'"
            @click="changeWorldChoice('theme')"
          >
            <span>Thèmes</span>
            <svg-icon type="mdi" :path="votePath" size="16" right></svg-icon>
          </v-btn>
        </v-btn-toggle>
      </v-row>
    </v-card>
    <v-row>
      <v-col v-if="worldChoice == 'country'" cols="12" class="">
        <WorldCountry key="country" />
      </v-col>
      <v-col v-if="worldChoice == 'zone'" cols="12" class="">
        <WorldZone key="zoneFromWorld" />
      </v-col>
      <v-col v-if="worldChoice == 'theme'" cols="12" class="">
        <WorldTheme key="themeFromWorld" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import WorldCountry from "@/component/list/world/country/List.vue";
import WorldZone from "@/component/list/world/zone/List.vue";
import WorldTheme from "@/component/list/world/theme/Home.vue";
import Vuex from "vuex";
import SvgIcon from "@jamescoyle/vue-icon";
import { mdiMap, mdiFlag, mdiVote } from "@mdi/js";

export default {
  components: {
    WorldCountry,
    WorldZone,
    WorldTheme,
    SvgIcon,
  },
  data: function () {
    return {
      mapPath: mdiMap,
      flagPath: mdiFlag,
      votePath: mdiVote,
    };
  },
  name: "world-list-country-and-zone-and-theme",
  methods: {
    ...Vuex.mapMutations("list", ["changeWorldChoice"]),
  },
  computed: {
    ...Vuex.mapState("list", ["worldName"]),
    ...Vuex.mapGetters("list", ["worldChoice"]),
  },
};
</script>
